import React, { FunctionComponent, useMemo } from 'react'

type Props = {
	bloks: Record<string, any>[]
	componentLookup: Record<string, FunctionComponent<any>>
	// eslint-disable-next-line react/require-default-props
	additionalProps?: Record<string, unknown>
}

export const BlokZone = ({
	bloks,
	componentLookup,
	additionalProps = undefined
}: Props) => {
	const renderedBloks = useMemo(
		() =>
			bloks?.map((blok) => {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				const { component, _uid } = blok

				if (typeof componentLookup[component] !== 'undefined') {
					const props: any = {
						key: blok._uid,
						blok
					}
					const CurrentComponent = componentLookup[component]

					return (
						<section key={_uid}>
							<CurrentComponent
								{...props}
								key={_uid}
								blok={blok}
								{...additionalProps}
							/>
						</section>
					)
				}

				return null
			}),
		[bloks, componentLookup, additionalProps]
	)

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{renderedBloks}</>
}
