import React, { useCallback } from 'react'

import { Icon, theme } from '@damen/ui'

import { WebinarItemProps } from './types'
import {
	CardItem,
	CardThumbnail,
	CardThumbnailImage,
	CardButton,
	CardItemDuration,
	CardItemText
} from './styles'

const WebinarItem: React.FC<WebinarItemProps> = ({
	hasBorder = true,
	isLast = false,
	durationUnit,
	onClick,
	title,
	video
}) => {
	const clickHandler = useCallback(() => {
		if (onClick) {
			onClick(video.videoId, title)
		}
	}, [onClick, title, video.videoId])

	return (
		<CardItem hasBorder={hasBorder} isLast={isLast}>
			<CardThumbnail onClick={clickHandler}>
				<CardThumbnailImage
					width={708}
					height={398}
					src={video.thumbnailUrl}
					alt={title}
				/>
				<CardButton />
			</CardThumbnail>
			<CardItemDuration>
				<Icon.Clock width={12} height={12} fill={theme.colors.grey} />
				<span>
					{video.formattedDuration} {durationUnit}
				</span>
			</CardItemDuration>
			<CardItemText>{title}</CardItemText>
		</CardItem>
	)
}

export default WebinarItem
