import styled from 'styled-components'

import { Button, theme } from '@damen/ui'

import { hexToRgb } from '@utils/helper'

import { TechnicalPapersItemCardProps } from './types'

export const CardItem = styled.div<TechnicalPapersItemCardProps>`
	display: block;
	width: 100%;
	padding: ${(props) => (props.hasBorder ? `24px 22px 16px` : `24px 0 16px`)};
	position: relative;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: ${(props) => (props.hasBorder ? `22px` : `0px`)};
		right: ${(props) => (props.hasBorder ? `22px` : `0px`)};
		height: 1px;
		background-color: ${theme.colors.greyAccentLight};
		display: ${(props) => (props.isLast ? `none` : `block`)};
	}

	@media ${theme.legacyMediaQueries.sm} {
		display: flex;
		height: ${(props) => (props.hasBorder ? '100%' : 'inherit')};
		flex-direction: column;

		background-color: ${(props) =>
			props.hasBorder ? theme.colors.white : `transparent`};
		padding: ${(props) => (props.hasBorder ? `24px` : `24px 0 16px`)};
		border-radius: ${(props) => (props.hasBorder ? `5px` : `0px`)};
		box-shadow: ${(props) =>
			props.hasBorder
				? `0 0 2px 0 ${hexToRgb(theme.colors.marineBlack, {
						alpha: 0.12,
						format: 'css'
				  })}, 0 0 3px 0 ${hexToRgb(theme.colors.marineBlack, {
						alpha: 0.1,
						format: 'css'
				  })}, 0 2px 4px 0 ${hexToRgb(theme.colors.marineBlack, {
						alpha: 0.08,
						format: 'css'
				  })};`
				: `none`};
	}

	@media ${theme.legacyMediaQueries.md} {
		padding: ${(props) =>
			props.hasBorder ? `32px 32px 24px` : `0 20px 12px;`};

		&::before {
			display: none;
		}
	}

	@media ${theme.legacyMediaQueries.lg} {
		padding: ${(props) =>
			props.hasBorder ? `32px 32px 24px` : `0 30px 0`};
	}
`

export const CardItemText = styled.p<TechnicalPapersItemCardProps>`
	margin: ${(props) => (props.hasBorder ? `0 0 26px` : `0 0 24px`)};
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.2px;
	color: ${theme.colors.marineBlack};

	@media ${theme.legacyMediaQueries.sm} {
		flex-grow: 1;
		flex-shrink: 1;
	}
`

export const CardItemButton = styled(Button.Default)`
	font-size: 14px;

	@media ${theme.legacyMediaQueries.md} {
		flex-grow: 0;
		flex-shrink: 0;
		margin: auto 0 0;
	}
`

export const CardItemTags = styled.ul`
	border-top: 1px solid ${theme.colors.greyAccentLight};
	margin: 24px 0 0;
	padding: 16px 0 0;
	overflow: hidden;
	flex-wrap: wrap;
	display: flex;
`

export const CardItemTagsItem = styled.li`
	color: ${theme.colors.grey};
	font-size: 14px;
	line-height: 30px;
	flex-grow: 0;
	flex-shrink: 0;

	span {
		display: inline-block;
		margin: 0 12px 0 0;
		padding: 0 8px;
		background-color: ${theme.colors.blueIce};
		color: ${theme.colors.marineBlack};
		font-size: 12px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.5px;
		white-space: nowrap;
	}
`
