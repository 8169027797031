import React, { useCallback } from 'react'

import { Icon, theme } from '@damen/ui'

import { ExtendedKnowledgeBaseVideoItemProps } from './types'
import {
	CardItem,
	CardThumbnail,
	CardThumbnailImage,
	CardButton,
	CardItemDuration,
	VideoCardItemText
} from './styles'

const KnowledgeBaseVideoItem: React.FC<ExtendedKnowledgeBaseVideoItemProps> = ({
	hasBorder = true,
	isLast = false,
	durationUnit,
	onClick,
	title,
	video
}) => {
	const clickHandler = useCallback(() => {
		if (onClick) {
			onClick(video.videoId, title)
		}
	}, [onClick, title, video.videoId])

	return (
		<CardItem hasBorder={hasBorder} isLast={isLast}>
			<CardThumbnail onClick={clickHandler}>
				<CardThumbnailImage src={video.thumbnailUrl} alt={title} />
				<CardButton />
			</CardThumbnail>
			<CardItemDuration>
				<Icon.Clock width={12} height={12} fill={theme.colors.grey} />
				<span>
					{video.formattedDuration} {durationUnit}
				</span>
			</CardItemDuration>
			<VideoCardItemText>{title}</VideoCardItemText>
		</CardItem>
	)
}

export default KnowledgeBaseVideoItem
