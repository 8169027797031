import styled from 'styled-components'

import { theme } from '@damen/ui'

export const CardThumbnail = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	margin-bottom: 24px;

	> span {
		position: absolute;
		width: 100%;
		height: 100%;
	}
`

export const CardItemDuration = styled.p`
	display: inline-flex;
	width: 100%;
	margin: 0 0 8px;
	font-size: 14px;
	letter-spacing: 0.2px;
	color: ${theme.colors.grey};

	> span {
		width: 40%;
	}
`

export const CardItemText = styled.p`
	margin: 0;
	font-size: 14px;
	font-weight: bold;
	line-height: 30px;
	letter-spacing: 0.44px;

	> span {
		width: 80%;
	}
`
