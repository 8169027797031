import React, { useCallback } from 'react'

import { Icon } from '@damen/ui'

import { ExtendedKnowledgeBaseDownloadItemProps } from './types'
import {
	CardItem,
	DownloadCardItemText,
	CardItemButton,
	CardItemTags,
	CardItemTagsItem
} from './styles'

const KnowledgeBaseDownloadItem: React.FC<
	ExtendedKnowledgeBaseDownloadItemProps
> = ({
	title,
	formattedFileSize,
	downloadUrl,
	buttonLabel,
	isLast,
	hasBorder
}) => {
	const clickHandler = useCallback(() => {
		window.open(downloadUrl, '_blank')
	}, [downloadUrl])

	return (
		<CardItem isLast={isLast} hasBorder={hasBorder}>
			<DownloadCardItemText isLast={isLast} hasBorder={hasBorder}>
				{title}
			</DownloadCardItemText>
			<CardItemButton
				text={buttonLabel}
				buttonType="text"
				colorType="blue"
				icon={Icon.FileDownload}
				iconPosition="right"
				iconSize={14}
				onClick={clickHandler}
			/>
			{formattedFileSize && (
				<CardItemTags>
					{formattedFileSize && (
						<CardItemTagsItem>
							<span>{formattedFileSize}</span>
						</CardItemTagsItem>
					)}
				</CardItemTags>
			)}
		</CardItem>
	)
}

export default KnowledgeBaseDownloadItem
