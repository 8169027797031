import React from 'react'

import SkeletonLoader from '@components/UI/SkeletonLoader'

import { CardItem } from '@components/WebinarItem/styles'

import { CardThumbnail, CardItemDuration, CardItemText } from './styles'
import { WebinarItemCardProps } from './types'

const WebinarItem: React.FC<WebinarItemCardProps> = ({
	hasBorder = false,
	isLast = false
}) => (
	<CardItem hasBorder={hasBorder} isLast={isLast}>
		<CardThumbnail>
			<SkeletonLoader width="100%" height="100%" />
		</CardThumbnail>
		<CardItemDuration>
			<SkeletonLoader height={21} />
		</CardItemDuration>
		<CardItemText>
			<SkeletonLoader height={21} />
		</CardItemText>
	</CardItem>
)

export default WebinarItem
