import React, { useCallback } from 'react'

import { Icon } from '@damen/ui'

import { TechnicalPapersItemProps } from './types'
import {
	CardItem,
	CardItemText,
	CardItemButton,
	CardItemTags,
	CardItemTagsItem
} from './styles'

const TechnicalPapersItem: React.FC<TechnicalPapersItemProps> = ({
	title,
	formattedFileSize,
	downloadUrl,
	buttonLabel,
	isLast,
	hasBorder,
	translatedType,
	translatedDivision
}) => {
	const clickHandler = useCallback(() => {
		window.open(downloadUrl, '_blank')
	}, [downloadUrl])

	return (
		<CardItem isLast={isLast} hasBorder={hasBorder}>
			<CardItemText isLast={isLast} hasBorder={hasBorder}>
				{title}
			</CardItemText>
			<CardItemButton
				text={buttonLabel}
				buttonType="text"
				colorType="blue"
				icon={Icon.FileDownload}
				iconPosition="right"
				iconSize={14}
				onClick={clickHandler}
			/>
			{(formattedFileSize || translatedType || translatedDivision) && (
				<CardItemTags>
					{formattedFileSize && (
						<CardItemTagsItem>
							<span>{formattedFileSize}</span>
						</CardItemTagsItem>
					)}
					{translatedType && (
						<CardItemTagsItem>
							<span>{translatedType}</span>
						</CardItemTagsItem>
					)}
					{translatedDivision && (
						<CardItemTagsItem>
							<span>{translatedDivision}</span>
						</CardItemTagsItem>
					)}
				</CardItemTags>
			)}
		</CardItem>
	)
}

export default TechnicalPapersItem
