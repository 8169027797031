import React, { useRef } from 'react'

import Modal from '@components/UI/Modal'
import { Video } from '@damen/ui'
import { VideoModalProps } from './types'

const VideoModal = ({
	isOpen,
	onClose,
	title,
	transparent,
	videoId
}: VideoModalProps) => {
	const elementRef = useRef()

	return (
		<Modal.Default
			isOpen={isOpen}
			onClose={onClose}
			transparent={transparent}
			title={title}
		>
			<div ref={elementRef}>
				{videoId && <Video.Default videoId={videoId} mute={false} />}
			</div>
		</Modal.Default>
	)
}

export default VideoModal
